import {mapGetters} from "vuex";
import {MutationSearchShopsResult} from "@/store/modules/SearchShopsResultModule/mutation";

const mixinStoreGetterShops = {
  computed: {
    ...mapGetters({
      shops:  `${MutationSearchShopsResult.MODULE_PATH}/${MutationSearchShopsResult.shops}`,
      shopsInsight:  `${MutationSearchShopsResult.MODULE_PATH}/${MutationSearchShopsResult.shopsInsight}`,
    })
  }
}

export {
  mixinStoreGetterShops
}
