<template>
  <MobileDefault v-if="isMobile">
    <div class="flex flex-row justify-space-between items-center container-bg-white container-space-mobile-default" style="margin-bottom: 0; padding-bottom: 0">
      <h2 class="title mobile-title">
        <span class="border-left-line mobile-view"></span>
        Shop có Sản phẩm bán chạy
      </h2>
<!--      <a-icon type="filter" style="margin-bottom: 8px; color: #838383"/>-->
    </div>
    <TableShop class="container-bg-white" style="margin: 0 12px 12px 12px" :load-type="null"/>
  </MobileDefault>
  <Default v-else>
    <TableShop class="container-bg-white" style="margin: 0 12px" :load-type="null"/>
  </Default>
</template>

<script>
import TableShop from "@/views/search/TableShop";
import MobileDefault from "@/layout/MobileDefault";
import Default from "@/layout/Default";
import {mixinStoreGetterGlobal} from "@/mixin/store/MixinGlobal";
export default {
name: "TableShopView",
  components: {
    MobileDefault,
    Default,
    TableShop
  },
  mixins: [
    mixinStoreGetterGlobal
  ]
}
</script>

<style scoped>

</style>
