<template>
  <div v-if="columns" :class="isMobile ? 'mobile-view' : ''">
    <a-spin :spinning="isLoading">
      <a-table
        v-if="dataSource"
        :tableLayout="isMobile ? 'fixed' : ''"
        :columns="columns"
        :rowKey="record => record.shop_platform_id"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="!shops"
        class="shop-subscribe"
        @change="handleTableChange"
      >
        <!--        <span slot="shop_name">Shop</span>-->

        <!--        <span slot="platform">Nơi bán</span>-->

        <template slot="shop_name" slot-scope="shop_name, shop">
          <a-row class="text-left" :style="isMobile ? 'font-size: 8px; margin: 4px 2px 6px 6px;' : ''">
            <!--          <img :src="shop.shop_url" :alt="shop.shop_name" class="img-shop mr-2">-->
            <div @click="onClickUrlDirect(shop.shop_url)" class="cursor-pointer">
              <span v-if="shop['official_type'] === 1" class="icon-mall-wrap"><MallFlag class="mr-2 icon-mall"/></span>
              <span class="font-medium">{{ shop_name || shop.shop_url }}</span>
            </div>
            <p v-if="isMobile" style="margin-top: 4px">
              Số sp: {{shop.product_total}}
            </p>
            <div :class="isMobile ? '' : 'flex flex-row justify-end mt-2'">
              <p class="" :style="isMobile ? 'font-size: 8px; margin-top: 4px' : 'text-align: right'">
              <span @click="onClickUrlDirect(shop.shop_url)" class="cursor-pointer text-primary-500">
                <a-icon type="shop" v-if="isDesktop"/>
                <img @click="onClickUrlDirect(shop.shop_url)" v-else-if="shop.shop_url" :src="getPlatformByUrl(shop.shop_url).urlLogo" :alt="getPlatformByUrl(shop.shop_url).name" class="cursor-pointer img-platform mr-2">
                Link shop
              </span>
              </p>
              <p class="" :style="isMobile ? 'font-size: 8px; margin-top: 4px' : 'text-align: right; margin-left: 12px'">
              <span @click="onClickGoToAnalyticShop(shop)" class="cursor-pointer text-primary-500">
                <a-icon type="stock" :class="!isDesktop ? 'mr-2' : ''"/>
                Phân tích
              </span>
              </p>
            </div>
          </a-row>
        </template>

        <template slot="shop_url" slot-scope="shop_url">
          <a-row type="flex" align="middle">
            <img @click="onClickUrlDirect(shop_url)" v-if="shop_url" :src="getPlatformByUrl(shop_url).urlLogo" :alt="getPlatformByUrl(shop_url).name" class="cursor-pointer img-platform mr-2">
          </a-row>
        </template>

        <template slot="product_total" slot-scope="number">
          {{ number | formatNumberHuman }}
        </template>

        <template slot="order_count_7d" slot-scope="number">
          {{ number | formatNumberHuman }}
        </template>

        <template slot="order_revenue_7d" slot-scope="number">
          {{ number | formatCurrency }}
        </template>

        <template slot="order_count_30d" slot-scope="number">
          {{ number | formatNumberHuman }}
        </template>

        <template slot="order_revenue_30d" slot-scope="number">
          {{ number | formatCurrency }}
        </template>

        <template slot="order_count_90d" slot-scope="number">
          {{ number | formatNumberHuman }}
        </template>

        <template slot="order_revenue_90d" slot-scope="number">
          {{ number | formatCurrency }}
        </template>
        <template slot="order_count_180d" slot-scope="number">
          {{ number | formatNumberHuman }}
        </template>

        <template slot="order_revenue_180d" slot-scope="number">
          {{ number | formatCurrency }}
        </template>

        <template slot="order_count_selected" slot-scope="number">
          {{ number | formatNumberHuman }}
        </template>

        <template slot="order_revenue_selected" slot-scope="number">
          {{ number | formatCurrency }}
        </template>

        <template slot="review_count_7d" slot-scope="number">
          {{ number | formatNumberHuman }}
        </template>

        <template slot="review_count_30d" slot-scope="number">
          {{ number | formatNumberHuman }}
        </template>

        <template slot="review_count_90d" slot-scope="number">
          {{ number | formatNumberHuman }}
        </template>
        <template slot="review_count_180d" slot-scope="number">
          {{ number | formatNumberHuman }}
        </template>
        <template slot="rating_count" slot-scope="number">
          {{ number | formatNumberHuman }}
        </template>

        <template slot="view_count" slot-scope="number">
          {{ number | formatNumberHuman }}
        </template>

        <template slot="order_revenue" slot-scope="number">
          {{ number | formatCurrency }}
        </template>
        <template slot="order_count" slot-scope="number">
          {{ number | formatNumberHuman }}
        </template>
      </a-table>
    </a-spin>
    <Pagination :current-page="currentPage" :total-page="(100-100%rowCount)/rowCount"
                v-if="isMobile && this.loadType === 'pagination'"
                class="container-bg-white px-2 py-3 text-sm" style="color: #8C8C8C; font-size: 8px;"
                @change="onChangePage"
    />
  </div>
</template>

<script>
import {formatCurrency, formatNumberHuman} from "@/helper/FormatHelper";
import {getPlatformByUrl} from "@/service/platform/PlatformCommonService";
import {MutationSearchFilter} from "@/store/modules/SearchFilterModule/mutation";
import {mixinStoreGetterShops} from "@/mixin/store/MixinShops";
import {mixinStoreGetterPermission} from "@/mixin/store/MixinUser";
import {PERMISSION} from "@/constant/general/PermissionConstant";
import {generateColumnsShopTable} from "@/service/market/MarketService";
import MallFlag from "@/components/product/MallFlag";
import {mixinStoreGetterGlobal} from "@/mixin/store/MixinGlobal";
import Pagination from "@/components/common/Pagination";

export default {
  name: "TableShop",
  components: {
    MallFlag,
    Pagination
  },
  props: {
    loadType: {
      default: 'pagination',
    },
    rowCount: {
      default: null
    },
    sortByProp: {
      default: null
    }
  },
  data() {
    let columns = []
    return {
      sortBy: null,
      isLoading: false,
      pagination: {},
      columns: columns,
      currentPage: 1
    }
  },
  async mounted() {
    this.pagination = this.loadType === 'pagination' && !this.isMobile
      ? {
        'current': 1,
        'pageSize': 10,
        'total': 100,
        'simple': true,
      }
      : false
    let availableColumns = PERMISSION[this.permissionAvailableName]?.availableDataIndexShopTable || []
    let durationDay = this.$store.state.SearchFilterModule.options?.durationDay
    this.columns = generateColumnsShopTable(durationDay).filter(item => availableColumns.includes(item.scopedSlots?.customRender || item.dataIndex))
    // let sortDefault = this.columns.filter(item => item.dataIndex === this.sortBy.split('__')[0])[0]
    // this.columns[this.columns.indexOf(sortDefault)].defaultSortOrder = this.sortBy.split('__')[1] === 'desc' ? 'descend' : 'ascend'
    // columns.filter(item => item.dataIndex === this.sortBy.split('__')[0])[0].defaultSortOrder = this.sortBy.split('__')[1] === 'desc' ? 'descend' : 'ascend'
    // this.columns = columns
    // if (this.iPlatforms?.[0] === PLATFORM_ID_OBJECT.lazada) {
    //   this.sortBy = 'rating_count__desc'
      // this.columns = this.columns.filter(item => item.dataIndex.indexOf('order') === -1 && item.dataIndex !== 'view_count')
    // } else {
      this.columns = this.columns.filter(item => item.dataIndex.indexOf('review') === -1 && item.dataIndex.indexOf('rating') === -1)

    if (this.isMobile) {
      this.columns = this.columns
        .filter(item => !['shop_url', 'platform', 'product_total'].includes(item.dataIndex))
        .map(item => {
          delete item.sorter
          item.width = item.dataIndex === 'shop_name' ? '28%' : '18%'
          item.title = item.title.replace('ản phẩm', 'p')
          return item
        })
    }
    // }
    if (!this.shops?.length) {
      await this.onSearchShops()
    }
  },
  methods: {
    getPlatformByUrl,
    async onSearchShops() {
      // todo: update paging
      this.isLoading = true
      let optionsFilter = {...this.$store.state.SearchFilterModule.options}
      if (typeof optionsFilter.durationDay !== 'number') {
        return
      }
      optionsFilter['limit'] = this.pagination.total || 100
      optionsFilter['page'] = this.pagination.current || 1
      optionsFilter['sort_by'] = this.sortBy ? this.sortBy : optionsFilter.durationDay !== 0 ? `order_revenue_${optionsFilter.durationDay}d__desc` : 'order_revenue_custom__desc'
      if (optionsFilter.durationDay !== 0) {
        delete optionsFilter.start_date
        delete optionsFilter.end_date
      }
      await this.$store.dispatch(`SearchFilterModule/${MutationSearchFilter.SEARCH_SHOPS}`, optionsFilter)
      this.isLoading = false
    },
    async handleTableChange(pagination, filters, sorter) {
      const pager = { ...this.pagination }
      pager.current = pagination.current
      this.pagination = pager
      if (sorter != null && Object.keys(sorter).length > 0) {
        let sort = sorter?.['field']
        if (sorter['order'] === 'ascend') {
          sort += `__asc`
        } else if (sorter['order'] === 'descend') {
          sort += `__desc`
        } else {
          return
        }
        this.sortBy = sort
      }
      this.onSearchShops()
    },
    onClickUrlDirect(shop_url) {
      window.open(shop_url, '_blank')
    },
    getShopName(shop_url) {
      let url = null
      try {
        url = new URL(shop_url)
      } catch (e) {
        return ''
      }
      let shopName = url.pathname.replaceAll('/', '_')
      return shopName
    },
    onClickGoToAnalyticShop(shop) {
      let shopUrl = shop?.['shop_url']
      let baseUrl = this.$router?.options?.base
      let url = `${baseUrl}?shop_url=${shopUrl}`
      // console.log(url)
      window.open(url, '_blank')
    },
    onChangePage(page) {
      this.currentPage = page
    },
  },
  computed: {
    iDurationDay: {
      get() {
        return this.$store.state.SearchFilterModule.iDurationDay
      },
    },
    iPlatforms: {
      get() {
        return this.$store.state.SearchFilterModule.iPlatforms
      }
    },
    dataSource() {
      if (this.rowCount) {
        return this.shops?.slice((this.currentPage - 1)*this.rowCount, this.currentPage * this.rowCount)
      }
      return this.shops
    }
  },
  filters: {
    formatCurrency: formatCurrency,
    formatNumberHuman: formatNumberHuman,
  },
  mixins: [
    mixinStoreGetterGlobal,
    mixinStoreGetterShops,
    mixinStoreGetterPermission
  ],
  watch: {
    permissionAvailableName() {
      let availableColumns = PERMISSION[this.permissionAvailableName]?.availableDataIndexShopTable || []
      this.columns = generateColumnsShopTable(this.iDurationDay).filter(item => availableColumns.includes(item.scopedSlots?.customRender || item.dataIndex))
    }
  }
}
</script>

<style lang="scss" scoped>
.shop-subscribe {
  .img-shop {
    width: 30px;
    height: 30px;
    object-fit: cover;
  }

  .img-platform {
    width: 25px;
    height: 25px;
    border-radius: 9999px;
    object-fit: cover;
  }
}

.mobile-view {
  .icon-mall-wrap {
    height: 10px;
    width: 20px;
    display: inline-block;
  }
  .icon-mall {
    transform: scale(0.5, 0.5);
    transform-origin: bottom left;
  }

  .img-platform {
    width: 10px;
    height: 10px;
  }

  /deep/ .ant-table {

  }

  /deep/ .ant-table-tbody > tr > td {
    font-size: 8px !important;
    padding: 2px !important;
    text-align: center;
  }

  /deep/ .ant-table-thead > tr > th {
    font-size: 10px !important;
    padding: 2px 6px !important;
    font-weight: 400;
    background: unset;
  }
}
</style>
